import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState('dark');
    const [isAnimating, setIsAnimating] = useState(false);

    const toggleTheme = () => {
        setIsAnimating(true);
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    useEffect(() => {
        document.body.style.backgroundColor = theme === 'dark' ? '#1f1f1f' : '#f5f5f5';
        document.body.classList.toggle('fade-in', isAnimating);

        const timer = setTimeout(() => {
            setIsAnimating(false);
            document.body.classList.toggle('fade-in');
        }, 300);

        return () => clearTimeout(timer);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};
