import React, { lazy, useState, useEffect, useContext } from 'react';
import { ThemeContext } from './ThemeContext';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';

import Navigation from './common/components/Navigation/Navigation';
import Footer from './common/components/Footer/Footer';
import CampaignBanner from './pages/Misc/CampaignBanner';
import { LoadingSpinner } from './common/components/UIElements/loadingAnimations';

import './app.css';

// import { ethers } from 'ethers';
// import { useScroll } from 'framer-motion';

/**
 * Lazy loading of components
 */
const Home = lazy(() => import('./pages/Home/Home'));
const About = lazy(() => import('./pages/About/About'));
const Blogs = lazy(() => import('./pages/Blogs/Blogs'));
const Testimonials = lazy(() => import('./pages/Testimonials'));
const Work = lazy(() => import('./pages/Work/Work'));
const Services = lazy(() => import('./pages/Services'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
const NotFound = lazy(() => import('./pages/StatusPages/404/new'));
const ProfileRedirect = lazy(() =>
  import('./features/ProfileRedirect/ProfileRedirect')
);
const Profiles = lazy(() => import('./pages/Profiles/Profiles'));
const AllProjects = lazy(() => import('./pages/Work/projects/AllProjects'));
const ProjectItem = lazy(() => import('./pages/Work/projects/ProjectItem'));
const Portfolio = lazy(() => import('./pages/Portfolio'));

const PrivacyPolicy = lazy(() => import('./pages/Misc/PrivacyPolicy'));
const Stats = lazy(() => import('./pages/Misc/Stats'));

const ServicePage = lazy(() =>
  import('./pages/Services/Page/ServicePage')
);

const App = () => {
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState(null);

  // const { scrollYProgress } = useScroll();

  // const loadBlockchainData = async () => {
  //   const provider = new ethers.providers.Web3Provider(window.ethereum);
  //   setProvider(provider);

  //   const network = await provider.getNetwork();

  //   window.ethereum.on('accountsChanged', async () => {
  //     const accounts = await window.ethereum.request({
  //       method: 'eth_requestAccounts'
  //     });
  //     const account = ethers.utils.getAddress(accounts[0]);
  //     setAccount(account);
  //   });
  // };

  // useEffect(() => {
  //   loadBlockchainData();
  // }, []);

  return (
    <React.Fragment>
      <Router>
        <CampaignBanner />
        <Navigation account={account} setAccount={setAccount} />

        <React.Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/about" element={<About />} /> */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blogs />} />
            <Route path="/testimonials" element={<Testimonials />} />

            <Route path="/work" element={<Work />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/:serviceName" element={<ServicePage />} />

            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/work/projects" element={<AllProjects />} />
            <Route path="/work/projects/:repoName" element={<ProjectItem />} />

            {/* <Route path="/profiles/:profile" element={<ProfileRedirect />} />
            <Route path="/profiles" element={<Profiles />} /> */}

            {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
            {/* <Route path="/stats" element={<Stats />} /> */}

            <Route path="/404" element={<NotFound />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </React.Suspense>

        <Footer />
      </Router>
    </React.Fragment>
  );
};

export default App;
