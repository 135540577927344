import React, { useContext } from 'react';
// import FooterLinks from './FooterLinks';
import './Footer.scss';
import { ThemeContext } from '../../../ThemeContext';
import { FaTwitter, FaLinkedin } from 'react-icons/fa';
import { Container, Row, Col } from 'react-bootstrap';

const logoURL =
  'https://ik.imagekit.io/pf0qkwdsv/Portfolio/VCSoft_logo_ekTEibM-L.svg?updatedAt=1730137008620';

const linksData = [
  { title: 'Home', url: '/' },
  { title: 'Services', url: '/services' },
  { title: 'Blog', url: '/blog' },
  { title: 'Past Projects', url: '/work/projects' },
  { title: 'Testimonials', url: '/testimonials' },
  { title: 'Contact', url: '/contact' }
];

const MotoComponent = (
  mobile
) => (
    <Col className={`footer__moto ${mobile === true ? 'footer__mobile': 'footer__desktop'}`}>
      <Row>
        <p className="footer__tagline">
          Let's connect and bring your ideas to life.
        </p>
      </Row>
      <Row>
        {' '}
        <div className="footer__social">
          <a
            href="https://x.com/chobanov_viktor"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.linkedin.com/in/viktor-chobanov-79b903122/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <FaLinkedin />
          </a>
        </div>
      </Row>
    </Col>
  );

const Footer = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className={`footer ${
        theme === 'dark' ? 'footer__dark' : 'footer__light'
      }`}
    >
      <div className="footer__container">
        {/* Left Section: Logo, tagline, and social icons */}
        <Container className="footer__left">
          <Row>
            <Col className="footer__logo">
              <img src={logoURL} alt="Logo" height={150} width={150} />
            </Col>

              <MotoComponent mobile={false} />
          </Row>

          <Row className="footer__mobile">
              <MotoComponent mobile={true} />
          </Row>
        </Container>

        {/* Right Section: Links in two columns */}
        <Container className="footer__right">
          <div className="footer__links">
            <div className="footer__link-column">
              {linksData
                .slice(0, Math.ceil(linksData.length / 2))
                .map((link, index) => (
                  <a key={index} href={link.url} className="footer__link">
                    {link.title}
                  </a>
                ))}
            </div>
            <div className="footer__link-column">
              {linksData
                .slice(Math.ceil(linksData.length / 2))
                .map((link, index) => (
                  <a key={index} href={link.url} className="footer__link">
                    {link.title}
                  </a>
                ))}
            </div>
          </div>
        </Container>
      </div>

      {/* Bottom Section: Copyright */}
      <div className="footer__copyright">
        &copy; {new Date().getFullYear()} Viktor Chobanov. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
